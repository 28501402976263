import React from 'react';
const PauseIcon = (svg?: React.SVGProps<SVGSVGElement>) => {
  return <svg {...svg} width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="PauseIcon" data-sentry-source-file="pause-icon.tsx">
      <rect x="0.570312" width="48" height="48" rx="24" fill="black" fillOpacity="0.19" data-sentry-element="rect" data-sentry-source-file="pause-icon.tsx" />
      <g clipPath="url(#clip0_15496_67890)" data-sentry-element="g" data-sentry-source-file="pause-icon.tsx">
        <path d="M18.5703 16.0003V32.0003C18.5703 32.1782 18.6177 32.3529 18.7077 32.5064C18.7977 32.6599 18.927 32.7866 19.0823 32.8734C19.2376 32.9602 19.4133 33.0041 19.5912 33.0003C19.7691 32.9966 19.9428 32.9455 20.0943 32.8523L33.0943 24.8523C33.2399 24.7628 33.3602 24.6375 33.4436 24.4884C33.527 24.3392 33.5708 24.1712 33.5708 24.0003C33.5708 23.8294 33.527 23.6613 33.4436 23.5122C33.3602 23.363 33.2399 23.2378 33.0943 23.1483L20.0943 15.1483C19.9428 15.055 19.7691 15.0039 19.5912 15.0002C19.4133 14.9965 19.2376 15.0403 19.0823 15.1271C18.927 15.214 18.7977 15.3407 18.7077 15.4941C18.6177 15.6476 18.5703 15.8224 18.5703 16.0003Z" fill="#FCFCFD" data-sentry-element="path" data-sentry-source-file="pause-icon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="pause-icon.tsx">
        <clipPath id="clip0_15496_67890" data-sentry-element="clipPath" data-sentry-source-file="pause-icon.tsx">
          <rect width="24" height="24" fill="white" transform="translate(12.5703 12)" data-sentry-element="rect" data-sentry-source-file="pause-icon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default PauseIcon;