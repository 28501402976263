import React, { useEffect, useRef } from "react";
import { Drawer, Grid, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { Swiper as SwiperType } from "swiper/types";
import { throttle } from "utils/helpers/throttle";
import { useRouter } from "next/router";
import NavigateIcon from "assets/icons/navigate-icon";
import ReelsSwiper from "./reels-swiper";
import styles from "./reelsModal.module.scss";
import "swiper/css";
import "swiper/css/pagination";
const ReelsModal = () => {
  const dispatch = useAppDispatch();
  const {
    pathname
  } = useRouter();
  const open = useAppSelector(state => state.favouritesReducer.reels.open);
  const {
    setReels
  } = favouritesReducerActions;
  const {
    md
  } = Grid.useBreakpoint();
  const swiperRef = useRef<SwiperType | null>(null);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const handleClose = () => {
    videoRefs.current.forEach(video => {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    });
    dispatch(setReels({
      open: false,
      data: undefined
    }));
  };
  const handlePrev = throttle(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(swiperRef.current.activeIndex - 1, 500);
    }
  }, 800); // 800ms limit

  const handleNext = throttle(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(swiperRef.current.activeIndex + 1, 500);
    }
  }, 800);
  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return md ? <Modal open={open} onCancel={handleClose} wrapClassName={styles.wrap} footer={false} centered closeIcon={false} width={1400}>
      <ReelsSwiper swiperRef={swiperRef} videoRefs={videoRefs} handleClose={handleClose} />
      <div className="absolute right-0 top-[50%] translate-y-[-50%] flex flex-col gap-4">
        <div className="flex h-[62px] w-[62px] items-center justify-center rounded-full cursor-pointer prev_reels select-none" style={{
        background: "rgba(255, 255, 255, 0.19)"
      }} onClick={handlePrev}>
          <NavigateIcon position="top" />
        </div>
        <div className="flex h-[62px] w-[62px] items-center justify-center rounded-full cursor-pointer next_reels select-none" style={{
        background: "rgba(255, 255, 255, 0.19)"
      }} onClick={handleNext}>
          <NavigateIcon position="bottom" />
        </div>
      </div>

    </Modal> : <Drawer open={open} onClose={handleClose} placement="bottom" width="100%" className={styles.drawer}>
      <ReelsSwiper swiperRef={swiperRef} videoRefs={videoRefs} handleClose={handleClose} />
    </Drawer>;
};
export default ReelsModal;