import React from 'react';
const MuteIcon = (svg: React.SVGProps<SVGSVGElement>) => {
  return <svg {...svg} width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="MuteIcon" data-sentry-source-file="mute-icon.tsx">
      <rect x="0.570312" width="48" height="48" rx="24" fill="black" fillOpacity="0.19" data-sentry-element="rect" data-sentry-source-file="mute-icon.tsx" />
      <g clipPath="url(#clip0_15496_67892)" data-sentry-element="g" data-sentry-source-file="mute-icon.tsx">
        <path d="M27.5703 20C28.3186 20.5612 28.8945 21.3211 29.2325 22.1933C29.5705 23.0655 29.657 24.0151 29.4823 24.934M28.1053 27.536C27.9382 27.7031 27.7594 27.8582 27.5703 28L28.1053 27.536Z" fill="#FCFCFD" data-sentry-element="path" data-sentry-source-file="mute-icon.tsx" />
        <path d="M27.5703 20C28.3186 20.5612 28.8945 21.3211 29.2325 22.1933C29.5705 23.0655 29.657 24.0151 29.4823 24.934M28.1053 27.536C27.9382 27.7031 27.7594 27.8582 27.5703 28" stroke="#FCFCFD" strokeWidth="1.99137" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="mute-icon.tsx" />
        <path d="M30.2695 17C31.8745 18.297 32.985 20.1062 33.415 22.1244C33.845 24.1427 33.5684 26.2474 32.6315 28.086M30.9555 30.385C30.7375 30.6016 30.5085 30.8068 30.2695 31" stroke="#FCFCFD" strokeWidth="1.99137" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="mute-icon.tsx" />
        <path d="M21.6393 17.0542L22.0703 16.5002C22.1577 16.3304 22.303 16.1975 22.4798 16.1255C22.6566 16.0535 22.8534 16.0471 23.0346 16.1075C23.2157 16.1678 23.3693 16.291 23.4675 16.4548C23.5658 16.6185 23.6023 16.812 23.5703 17.0002V19.0002M23.5703 23.0002V31.0002C23.6023 31.1885 23.5658 31.3819 23.4675 31.5457C23.3693 31.7094 23.2157 31.8326 23.0346 31.893C22.8534 31.9534 22.6566 31.947 22.4798 31.8749C22.303 31.8029 22.1577 31.67 22.0703 31.5002L18.5703 27.0002H16.5703C16.3051 27.0002 16.0507 26.8949 15.8632 26.7073C15.6757 26.5198 15.5703 26.2654 15.5703 26.0002V22.0002C15.5703 21.735 15.6757 21.4806 15.8632 21.2931C16.0507 21.1056 16.3051 21.0002 16.5703 21.0002H18.5703L19.8643 19.3362" fill="#FCFCFD" data-sentry-element="path" data-sentry-source-file="mute-icon.tsx" />
        <path d="M21.6393 17.0542L22.0703 16.5002C22.1577 16.3304 22.303 16.1975 22.4798 16.1255C22.6566 16.0535 22.8534 16.0471 23.0346 16.1075C23.2157 16.1678 23.3693 16.291 23.4675 16.4548C23.5658 16.6185 23.6023 16.812 23.5703 17.0002V19.0002M23.5703 23.0002V31.0002C23.6023 31.1885 23.5658 31.3819 23.4675 31.5457C23.3693 31.7094 23.2157 31.8326 23.0346 31.893C22.8534 31.9534 22.6566 31.947 22.4798 31.8749C22.303 31.8029 22.1577 31.67 22.0703 31.5002L18.5703 27.0002H16.5703C16.3051 27.0002 16.0507 26.8949 15.8632 26.7073C15.6757 26.5198 15.5703 26.2654 15.5703 26.0002V22.0002C15.5703 21.735 15.6757 21.4806 15.8632 21.2931C16.0507 21.1056 16.3051 21.0002 16.5703 21.0002H18.5703L19.8643 19.3362" stroke="#FCFCFD" strokeWidth="1.99137" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="mute-icon.tsx" />
        <path d="M15.5703 15L33.5703 33" stroke="#FCFCFD" strokeWidth="1.99137" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="mute-icon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="mute-icon.tsx">
        <clipPath id="clip0_15496_67892" data-sentry-element="clipPath" data-sentry-source-file="mute-icon.tsx">
          <rect width="24" height="24" fill="white" transform="translate(12.5703 12)" data-sentry-element="rect" data-sentry-source-file="mute-icon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default MuteIcon;