import { useInfiniteQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constants/queryKeys";
import { fetchAllReels } from "./queryFunctions";
import { Pagination } from "utils/models/pagination";
import { MainReelsModel } from "pages/main/utils/models/main-reels-model";
import { useRouter } from "next/router";
import { stringify } from "querystring";
import { cleanObject } from "utils/helpers/cleanObject";


export const useGetAllReels = (enabled?:boolean) =>{
  const { query } = useRouter()

  return useInfiniteQuery<Pagination<MainReelsModel>>({
    queryKey: [queryKeys.ALL_REELS, stringify(cleanObject(query))],
    queryFn: ({ pageParam = 1 }) => {
      return fetchAllReels(pageParam, 20, query)
    },
    getNextPageParam: (lastPage, page) => {
      const total_pages = Math.ceil(lastPage?.total / 20)
      if (page.length < total_pages) {
        return page.length + 1;
      }
      return undefined;
    },
    refetchOnWindowFocus: false,
    enabled,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
    retry: false
  });
}
  