import React from 'react';
interface Prop {
  position: "top" | "bottom";
}
const NavigateIcon: React.FC<Prop> = ({
  position
}) => {
  return <div className={`transform ${position === "top" ? "rotate-0" : "rotate-180"}`} data-sentry-component="NavigateIcon" data-sentry-source-file="navigate-icon.tsx">
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="navigate-icon.tsx">
        <path fillRule="evenodd" clipRule="evenodd" d="M27.0496 13.881C27.559 14.4091 27.5595 15.266 27.0507 15.7949C26.5418 16.3236 25.7161 16.3242 25.2065 15.7961L18.3042 8.64143L18.3042 28.3959C18.3042 29.1434 17.7204 29.7493 17.0002 29.7493C16.28 29.7493 15.6962 29.1434 15.6962 28.3959L15.6962 8.66552L8.79384 15.8202C8.28425 16.3483 7.45857 16.3477 6.94963 15.819C6.44081 15.2901 6.44134 14.4332 6.9508 13.905L17.0118 3.47656L27.0496 13.881Z" fill="#FCFCFD" data-sentry-element="path" data-sentry-source-file="navigate-icon.tsx" />
      </svg>
    </div>;
};
export default NavigateIcon;