import React from 'react';
const SoundIcon = (svg?: React.SVGProps<SVGSVGElement>) => {
  return <svg {...svg} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="SoundIcon" data-sentry-source-file="sound-icon.tsx">
      <rect width="48" height="48" rx="24" fill="black" fillOpacity="0.19" data-sentry-element="rect" data-sentry-source-file="sound-icon.tsx" />
      <g clipPath="url(#clip0_15453_84438)" data-sentry-element="g" data-sentry-source-file="sound-icon.tsx">
        <path d="M27 20C27.621 20.4657 28.125 21.0697 28.4721 21.7639C28.8193 22.4582 29 23.2238 29 24C29 24.7762 28.8193 25.5418 28.4721 26.2361C28.125 26.9303 27.621 27.5343 27 28" fill="#FCFCFD" data-sentry-element="path" data-sentry-source-file="sound-icon.tsx" />
        <path d="M27 20C27.621 20.4657 28.125 21.0697 28.4721 21.7639C28.8193 22.4582 29 23.2238 29 24C29 24.7762 28.8193 25.5418 28.4721 26.2361C28.125 26.9303 27.621 27.5343 27 28" stroke="#FCFCFD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="sound-icon.tsx" />
        <path d="M29.6992 17C30.7432 17.8437 31.5852 18.9101 32.1637 20.1213C32.7421 21.3325 33.0424 22.6578 33.0424 24C33.0424 25.3422 32.7421 26.6675 32.1637 27.8787C31.5852 29.0899 30.7432 30.1563 29.6992 31" stroke="#FCFCFD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="sound-icon.tsx" />
        <path d="M18 27.0002H16C15.7348 27.0002 15.4804 26.8949 15.2929 26.7073C15.1054 26.5198 15 26.2654 15 26.0002V22.0002C15 21.735 15.1054 21.4806 15.2929 21.2931C15.4804 21.1056 15.7348 21.0002 16 21.0002H18L21.5 16.5002C21.5874 16.3304 21.7326 16.1975 21.9095 16.1255C22.0863 16.0535 22.2831 16.0471 22.4643 16.1075C22.6454 16.1678 22.799 16.291 22.8972 16.4548C22.9955 16.6185 23.0319 16.812 23 17.0002V31.0002C23.0319 31.1885 22.9955 31.3819 22.8972 31.5457C22.799 31.7094 22.6454 31.8326 22.4643 31.893C22.2831 31.9534 22.0863 31.947 21.9095 31.8749C21.7326 31.8029 21.5874 31.67 21.5 31.5002L18 27.0002Z" fill="#FCFCFD" stroke="#FCFCFD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="sound-icon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="sound-icon.tsx">
        <clipPath id="clip0_15453_84438" data-sentry-element="clipPath" data-sentry-source-file="sound-icon.tsx">
          <rect width="24" height="24" fill="white" transform="translate(12 12)" data-sentry-element="rect" data-sentry-source-file="sound-icon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default SoundIcon;