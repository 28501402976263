import { useInfiniteQuery } from "@tanstack/react-query";
import { Pagination } from "utils/models/pagination";
import { fetchLikedViews } from "./query-functions";
import { queryKeys } from "pages/app/utils/constants/queryKeys";
import { NewApartmentCardModel } from "utils/models/apartmentModel";
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel";
import { MainReelsModel } from "pages/main/utils/models/main-reels-model";
import { MagazineType } from "pages/magazines/utils/models/magazineModel";

export const useGetLikedView = (object_key: string,enabled?:boolean) => {

  return useInfiniteQuery<Pagination<
  NewApartmentCardModel | NewResidentalComplexModel | MainReelsModel | MagazineType
>>({
    queryKey: [queryKeys.LIKED_VIEW, object_key],
    queryFn: ({ pageParam:page = 1  }) => fetchLikedViews(object_key, page),
    getNextPageParam: (lastPage, allPages) => {
        const totalItems = lastPage.total;
        const itemsLoaded = allPages.reduce((acc, page) => acc + page.data.length, 0);

        const hasMore = itemsLoaded < totalItems;
        
        
        return hasMore ? lastPage.current_page + 1 : undefined;
      },
      enabled:enabled && !!object_key,
      refetchOnWindowFocus:false,
      retry:false
  });
};
