import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { useCreateUserInteractions } from 'pages/detailed-magazine/service/mutations';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { MainReelsModel } from 'pages/main/utils/models/main-reels-model';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import { rootPaths } from 'utils/constants/rootPaths';
import { useGetAllReels } from 'pages/reels/services/queries';
import { useGetMainReels } from 'pages/main/services/queries';
import { useRouter } from 'next/router';
import { throttle } from 'utils/helpers/throttle';
import ReelsVideoContent from './reels-video-content';
import ReelsImageContent from './reels-image-content';
import ReelsHeartIcon from 'assets/icons/reels-heart-icon';
import ForwardIcon from 'assets/icons/forward-icon';
import ShareModal from 'pages/detailed-residential-complex/components/share-modal/ShareModal';
import PhoneIcon from 'assets/icons/PhoneIcon';
import "swiper/css";
import "swiper/css/pagination";
import { useGetLikedView } from 'pages/favourites/services';
interface IProps {
  swiperRef: React.MutableRefObject<SwiperType | null>;
  videoRefs: React.MutableRefObject<(HTMLVideoElement | null)[]>;
  handleClose: () => void;
}
const ReelsSwiper: React.FC<IProps> = ({
  swiperRef,
  videoRefs,
  handleClose
}) => {
  const {
    pathname
  } = useRouter();
  const {
    data: reels,
    fetchNextPage
  } = useGetAllReels(pathname.includes("reels"));
  const {
    data: likedData,
    fetchNextPage: fetchNextLikedData,
    hasNextPage,
    isFetchingNextPage
  } = useGetLikedView('story_id', pathname.includes('favourites'));
  const {
    data: mainReels
  } = useGetMainReels(!pathname.includes("reels") && !pathname.includes('favourites'));
  const reelsData = useMemo(() => {
    if (pathname.includes("reels")) {
      return reels?.pages.flatMap(page => page.data) || [];
    } else if (pathname.includes("favourites")) {
      return likedData?.pages.flatMap(page => page.data) || [];
    }
    return mainReels || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reels, mainReels]) as MainReelsModel[];
  const dispatch = useAppDispatch();
  const {
    setReels
  } = favouritesReducerActions;
  const data = useAppSelector(state => state.favouritesReducer.reels.data);
  const open = useAppSelector(state => state.favouritesReducer.reels.open);
  const userInteraction = useCreateUserInteractions();
  const [shareOpen, setShareOpen] = useState(false);
  const likedReelsRef = useRef<{
    [key: number]: boolean;
  }>({});
  const [likedReels, setLikedReels] = useState<{
    [key: number]: boolean;
  }>({});
  const handleUserInteraction = () => {
    setLikedReels(prev => {
      const newLikes = {
        ...prev,
        [data?.id as number]: !prev[data?.id as number]
      };
      likedReelsRef.current = newLikes;
      return newLikes;
    });
    userInteraction.mutate({
      interaction_type: "like",
      story_id: data?.id as number
    });
  };
  const handlePhoneInteraction = () => {
    userInteraction.mutate({
      interaction_type: "click_phone_button",
      complex_id: data?.complex?.id
    });
  };
  const prevIndexRef = useRef<number | null>(null);
  const handleSlideChange = (swiper: SwiperType) => {
    if (prevIndexRef.current === swiper.activeIndex) return;
    prevIndexRef.current = swiper.activeIndex;
    videoRefs.current.forEach(video => video?.pause());
    const activeSlide = reelsData?.[swiper.activeIndex];
    dispatch(setReels({
      open: true,
      data: activeSlide
    }));
    if (activeSlide?.is_video) {
      requestAnimationFrame(() => {
        videoRefs.current[swiper.activeIndex]?.play();
      });
    }
    if (swiper.activeIndex === reelsData?.length - 4) {
      if (pathname.includes('reels')) {
        fetchNextPage();
      } else if (hasNextPage && !isFetchingNextPage) {
        fetchNextLikedData();
      }
    }
  };
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (open && data?.id) {
      timeoutRef.current = setTimeout(() => {
        userInteraction.mutate({
          interaction_type: "view",
          story_id: Number(data.id)
        });
      }, 5000);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id, open]);
  useEffect(() => {
    if (!reelsData) return;
    const initialLikes = reelsData.reduce((acc, reel) => {
      if (likedReelsRef.current[reel.id] === undefined) {
        acc[reel.id] = !!reel.liked;
      } else {
        acc[reel.id] = likedReelsRef.current[reel.id];
      }
      return acc;
    }, {} as {
      [key: number]: boolean;
    });
    likedReelsRef.current = {
      ...likedReelsRef.current,
      ...initialLikes
    };
    setLikedReels(initialLikes);
  }, [reelsData]);
  const activeIndex = reelsData.findIndex(reel => reel?.id === data?.id);
  useEffect(() => {
    if (!open || !data) return;
    videoRefs.current.forEach(video => video?.pause());
    if (swiperRef.current && activeIndex !== -1) {
      setTimeout(() => {
        swiperRef.current?.slideTo(activeIndex, 0);
      }, 50); // 50ms kechikish beriladi
    }
    const activeSlide = reelsData?.[activeIndex];
    if (activeSlide?.is_video) {
      setTimeout(() => {
        videoRefs.current[activeIndex]?.play();
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data]);
  const shareUrl = data?.complex ? `${process.env.NEXT_PUBLIC_FRONT_URL}/${data?.complex?.city_url_name}${rootPaths.RESIDENTIAL_COMPLEXES}/${data?.complex?.slug}-${data?.complex?.id}` : `${process.env.NEXT_PUBLIC_FRONT_URL}${rootPaths.BUILDERS}/${data?.brand?.slug}`;
  const rightContent = (reel: MainReelsModel) => <div className="absolute md:right-0 right-4 bottom-[140px] z-10 flex flex-col gap-3" data-sentry-component="rightContent" data-sentry-source-file="reels-swiper.tsx">
      {reel?.complex && reel?.complex?.phone && <a href={"tel:+998557050705"} className="w-[38px] h-[38px] md:w-[62px] md:h-[62px] rounded-full bg-[#00000030] cursor-pointer flex items-center justify-center" onClick={handlePhoneInteraction}>
          <PhoneIcon className='customIcon' />
        </a>}
      <div className={`w-[38px] h-[38px] md:w-[62px] md:h-[62px] rounded-full cursor-pointer flex items-center justify-center ${likedReels[data?.id as number] ? "bg-[#00A389]" : "bg-[#00000030]"}`} onClick={handleUserInteraction}>
        <ReelsHeartIcon data-sentry-element="ReelsHeartIcon" data-sentry-source-file="reels-swiper.tsx" />
      </div>
      <div className="w-[38px] h-[38px] md:w-[62px] md:h-[62px] rounded-full bg-[#00000030] cursor-pointer flex items-center justify-center" onClick={() => setShareOpen(true)}>
        <ForwardIcon data-sentry-element="ForwardIcon" data-sentry-source-file="reels-swiper.tsx" />
      </div>
    </div>;
  const throttledSlideChange = throttle((swiper: SwiperType) => {
    handleSlideChange(swiper);
  }, 500);
  const currentIndex = [activeIndex - 1, activeIndex, activeIndex + 1];
  return <>
      <Swiper direction="vertical" slidesPerView={1} spaceBetween={10} pagination={{
      clickable: true
    }} modules={[Navigation]} className='md:w-[570px] md:h-[800px] h-screen w-full' touchRatio={0.5} followFinger={false} onSwiper={swiper => {
      if (swiperRef.current) {
        swiperRef.current.destroy();
      }
      swiperRef.current = swiper;
    }} onSlideChange={throttledSlideChange} lazy={true} speed={500} onTouchMove={e => {
      if (e.$el.closest('.scrollable-div')) {
        e.allowTouchMove = false;
      }
    }} onTouchEnd={e => {
      e.allowTouchMove = true;
    }} data-sentry-element="Swiper" data-sentry-source-file="reels-swiper.tsx">
        {reelsData?.map((reel, index) => <SwiperSlide key={reel?.id ?? `reel-${index}`}>
            <div className="relative w-full">
              <div className="md:h-[800px] md:w-[481px] rounded-none md:rounded-xl h-screen w-full overflow-hidden relative">
                {open && currentIndex.includes(index) ? <>
                  {reel?.is_video ? <ReelsVideoContent handleClose={handleClose} index={index} reel={reel} videoRefs={videoRefs} /> : <ReelsImageContent reel={reel} handleClose={handleClose} />}
                </> : null}
                <div className='block md:hidden'>
                  {rightContent(reel)}
                </div>
              </div>
              <div className='md:block hidden'>
                {rightContent(reel)}
              </div>
            </div>
          </SwiperSlide>)}
      </Swiper>
      <ShareModal open={shareOpen} setOpen={setShareOpen} copyUrl={shareUrl} data-sentry-element="ShareModal" data-sentry-source-file="reels-swiper.tsx" />
    </>;
};
export default ReelsSwiper;