import React from 'react';
import { useAppDispatch } from 'hooks/reduxHooks';
import { MainReelsModel } from 'pages/main/utils/models/main-reels-model';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import VideoIcon from 'assets/icons/VideoIcon';
import CustomImage from 'common/custom-image';
interface IProps {
  item: MainReelsModel;
}
const MainReelsCard: React.FC<IProps> = ({
  item
}) => {
  const dispatch = useAppDispatch();
  const {
    setReels
  } = favouritesReducerActions;
  const handleOpen = () => {
    dispatch(setReels({
      open: true,
      data: item
    }));
  };
  return <div className='h-full rounded-xl overflow-hidden relative flex items-center justify-center cursor-pointer' onClick={handleOpen} data-sentry-component="MainReelsCard" data-sentry-source-file="main-reels-card.tsx">
      <div className='absolute top-2.5 right-2.5 z-[11]'>
        {item?.is_video ? <VideoIcon width={20} height={20} /> : null}
      </div>

      <div className='w-full h-full overflow-hidden'>
        <CustomImage alt={`alt - ${item?.id}`} src={item?.image} title={`title - ${item?.id}`} data-sentry-element="CustomImage" data-sentry-source-file="main-reels-card.tsx" />
      </div>
      <div className='absolute w-full bottom-0 left-0 h-[93px] rounded-xl flex items-end p-2.5' style={{
      background: "linear-gradient(180deg, rgba(118, 118, 118, 0.00) 0%, #000 135.44%)"
    }}>
        <div className='flex items-center gap-2 w-full'>
          <div className='md:w-[38px] md:h-[38px] md:min-w-[38px] md:min-h-[38px] w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-full overflow-hidden border border-solid border-white'>
            <CustomImage alt={item?.brand?.name} src={item?.brand?.logo} data-sentry-element="CustomImage" data-sentry-source-file="main-reels-card.tsx" />
          </div>
          <span className='text-white font-semibold md:text-sm text-xs'>{item?.brand?.name}</span>
        </div>
      </div>
    </div>;
};
export default MainReelsCard;