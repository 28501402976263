import React from 'react';
const ForwardIcon = () => {
  return <svg className='customIcon' width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="ForwardIcon" data-sentry-source-file="forward-icon.tsx">
      <g clipPath="url(#clip0_16054_29274)" data-sentry-element="g" data-sentry-source-file="forward-icon.tsx">
        <path d="M18.4169 5.66602V11.3327C9.10235 12.789 5.6386 20.949 4.25027 28.3327C4.19785 28.6245 11.8776 19.8865 18.4169 19.8327V25.4993L29.7503 15.5827L18.4169 5.66602Z" fill="#FCFCFD" stroke="#FCFCFD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="forward-icon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="forward-icon.tsx">
        <clipPath id="clip0_16054_29274" data-sentry-element="clipPath" data-sentry-source-file="forward-icon.tsx">
          <rect width="34" height="34" fill="white" data-sentry-element="rect" data-sentry-source-file="forward-icon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default ForwardIcon;