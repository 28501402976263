import React, { useRef, useState } from 'react';
import { layoutLang } from 'utils/language/layout';
import { MainReelsModel } from 'pages/main/utils/models/main-reels-model';
import { useRouter } from 'next/router';
import { useAppDispatch } from 'hooks/reduxHooks';
import { userReducerActions } from 'store/reducers/userReducer';
import CloseIcon from 'assets/icons/CloseIcon';
import CustomImage from 'common/custom-image';
import CustomLink from 'common/custom-link';
import { rootPaths } from 'utils/constants/rootPaths';
interface IProps {
  reel: MainReelsModel;
  handleClose: () => void;
}
const ReelsImageContent: React.FC<IProps> = ({
  reel,
  handleClose
}) => {
  const dispatch = useAppDispatch();
  const {
    locale
  } = useRouter();
  const {
    setCallBackModal
  } = userReducerActions;
  const [more, setMore] = useState(false);
  const contentRef = useRef(false);
  const handleMore = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setMore(!more);
  };
  const handleCallBack = () => {
    dispatch(setCallBackModal({
      visible: true,
      type: reel?.brand?.id ? 'brand' : 'complex',
      id: reel?.brand?.id ?? reel?.complex?.id
    }));
  };
  const brandUrl = `${process.env.NEXT_PUBLIC_FRONT_URL}${rootPaths.BUILDERS}/${reel?.brand?.slug}`;
  const complexUrl = `${process.env.NEXT_PUBLIC_FRONT_URL}/${reel?.complex?.city_url_name}${rootPaths.RESIDENTIAL_COMPLEXES}/${reel?.complex?.slug}-${reel?.complex?.id}`;
  return <div className="flex items-center justify-center h-full rounded-xl overflow-hidden group cursor-pointer" data-sentry-component="ReelsImageContent" data-sentry-source-file="reels-image-content.tsx">
      <div className='absolute w-full h-full top-0 left-0 rounded-xl overflow-hidden' style={{
      backgroundImage: `url(${reel?.image})`,
      filter: "blur(10px)",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }} />
      <div className="imgContent absolute flex items-center justify-between z-10 top-[68px] md:top-0 w-full p-5 group-hover:opacity-100 opacity-100 md:opacity-0 transition-all duration-150">
        <span className="text-base font-bold text-white">UYSOT REELS</span>
        <div className="md:w-12 md:h-12 w-[38px] h-[38px] flex items-center justify-center cursor-pointer rounded-full bg-[#00000030]" onClick={handleClose}>
          <CloseIcon fill="#fff" data-sentry-element="CloseIcon" data-sentry-source-file="reels-image-content.tsx" />
        </div>
      </div>
      <div className="w-full">
        <CustomImage alt={`alt - ${reel?.id}`} src={reel?.image} data-sentry-element="CustomImage" data-sentry-source-file="reels-image-content.tsx" />
      </div>
      <div className='absolute w-full bottom-0 left-0 h-max rounded-none md:rounded-xl py-3 px-5 z-[10000]' style={{
      background: "linear-gradient(180deg, rgba(118, 118, 118, 0.00) 0%, #000 135.44%)"
    }}>
        <div className='flex flex-col gap-5'>
          <CustomLink href={brandUrl} className='flex items-center gap-2 w-full' data-sentry-element="CustomLink" data-sentry-source-file="reels-image-content.tsx">
            <div className='w-[38px] h-[38px] min-w-[38px] min-h-[38px] rounded-full overflow-hidden border border-solid border-white'>
              <CustomImage alt={reel?.brand?.name} src={reel?.brand?.logo} data-sentry-element="CustomImage" data-sentry-source-file="reels-image-content.tsx" />
            </div>
            <span className='text-white font-semibold md:text-sm text-xs'>{reel?.brand?.name}</span>
          </CustomLink>
          <div className='flex items-end gap-4' onClick={e => handleMore(e)}>
            <div className={`text-white font-medium scrollable-div ${more ? 'w-full line-clamp-none mainFilter max-h-[160px] !overflow-y-auto' : reel?.caption?.length > 100 ? `w-[75%] line-clamp-2` : ''}`} onTouchEndCapture={e => {
            if (!contentRef.current) {
              e.stopPropagation();
            }
          }} onScroll={e => {
            if (e.currentTarget.scrollTop === 0) {
              contentRef.current = true;
            }
            if (e.currentTarget.scrollTop + e.currentTarget.clientHeight === e.currentTarget.scrollHeight) {
              contentRef.current = true;
            } else {
              contentRef.current = false;
            }
          }}>
              {reel?.caption}</div>
            {reel?.caption?.length > 100 ? <div className={`text-white underline cursor-pointer ${more ? 'hidden' : 'block'}`} onClick={e => handleMore(e)}>
                {layoutLang[locale || 'ru']['yana']}
              </div> : ''}
          </div>
        </div>
        <div className='pb-2 pt-4 flex items-center gap-2.5 cursor-pointer'>
          <div className='flex-1 h-[50px] flex items-center justify-center rounded-xl bg-[#00A389] text-white text-[15px] font-semibold' onClick={handleCallBack}>
            {layoutLang[locale || 'ru']['Konsultatsiya']}
          </div>
          <CustomLink href={reel?.complex ? complexUrl : brandUrl} className='flex-1 h-[50px] flex items-center justify-center rounded-xl bg-[#F2F2F0] text-black text-[15px] font-semibold' data-sentry-element="CustomLink" data-sentry-source-file="reels-image-content.tsx">
            {layoutLang[locale || 'ru']['Batafsil']}
          </CustomLink>
        </div>
      </div>
    </div>;
};
export default ReelsImageContent;