import React from 'react';
const ReelsHeartIcon = () => {
  return <svg className='customIcon' width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="ReelsHeartIcon" data-sentry-source-file="reels-heart-icon.tsx">
      <g clipPath="url(#clip0_16044_839)" data-sentry-element="g" data-sentry-source-file="reels-heart-icon.tsx">
        <path d="M9.8862 4.35514C11.14 4.1415 12.4258 4.21197 13.6488 4.56136C14.8718 4.91075 16.0008 5.53014 16.9525 6.37389L17.005 6.42064L17.0531 6.37814C17.9615 5.58097 19.0294 4.98668 20.1856 4.6349C21.3418 4.28313 22.5598 4.18195 23.7582 4.33814L24.1067 4.38914C25.6173 4.64996 27.0292 5.31444 28.193 6.31222C29.3568 7.30999 30.2291 8.60392 30.7175 10.057C31.2059 11.51 31.2923 13.0681 30.9675 14.5663C30.6427 16.0644 29.9187 17.4468 28.8724 18.5671L28.6174 18.8292L28.5494 18.8873L17.9952 29.3409C17.7516 29.5819 17.429 29.7265 17.087 29.7479C16.7449 29.7693 16.4068 29.6659 16.1351 29.4571L16.002 29.3409L5.38687 18.8264C4.26235 17.7322 3.46261 16.3482 3.07623 14.8275C2.68984 13.3068 2.73189 11.7089 3.19772 10.2107C3.66355 8.71242 4.53497 7.37235 5.71548 6.33885C6.89599 5.30536 8.33952 4.61877 9.8862 4.35514Z" fill="#FCFCFD" data-sentry-element="path" data-sentry-source-file="reels-heart-icon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="reels-heart-icon.tsx">
        <clipPath id="clip0_16044_839" data-sentry-element="clipPath" data-sentry-source-file="reels-heart-icon.tsx">
          <rect width="34" height="34" fill="white" data-sentry-element="rect" data-sentry-source-file="reels-heart-icon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default ReelsHeartIcon;