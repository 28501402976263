/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { MainReelsModel } from 'pages/main/utils/models/main-reels-model';
import { layoutLang } from 'utils/language/layout';
import { useAppDispatch } from 'hooks/reduxHooks';
import { userReducerActions } from 'store/reducers/userReducer';
import { rootPaths } from 'utils/constants/rootPaths';
import MuteIcon from 'assets/icons/mute-icon';
import PauseIcon from 'assets/icons/pause-icon';
import SoundIcon from 'assets/icons/sound-icon';
import CustomImage from 'common/custom-image';
import CloseIcon from 'assets/icons/CloseIcon';
import CustomLink from 'common/custom-link';
interface IProps {
  reel: MainReelsModel;
  handleClose: () => void;
  videoRefs: React.MutableRefObject<(HTMLVideoElement | null)[]>;
  index: number;
}
const ReelsVideoContent: React.FC<IProps> = ({
  reel,
  videoRefs,
  index,
  handleClose
}) => {
  const dispatch = useAppDispatch();
  const {
    setCallBackModal
  } = userReducerActions;
  const {
    locale
  } = useRouter();
  const [more, setMore] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlayed, setIsPlayed] = useState<Record<number, boolean>>({});
  const contentRef = useRef(false);
  const handleMore = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setMore(!more);
  };
  const toggleMute = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    const videoElement = videoRefs.current[index];
    if (!videoElement) return;
    setIsMuted(prevMuted => {
      videoElement.muted = !prevMuted;
      return !prevMuted;
    });
  };
  const handlePlay = (e: any) => {
    e.stopPropagation();
    if (!videoRefs.current || !videoRefs.current[index]) return;
    const videoElement = videoRefs.current[index];
    if (videoElement !== null) {
      if (videoElement.paused) {
        videoElement.play();
        setIsPlayed(prev => ({
          ...prev,
          [index]: true
        }));
      } else {
        videoElement.pause();
        setIsPlayed(prev => ({
          ...prev,
          [index]: false
        }));
      }
    }
  };
  const handleCallBack = () => {
    dispatch(setCallBackModal({
      visible: true,
      type: reel?.brand?.id ? 'brand' : 'complex',
      id: reel?.brand?.id ?? reel?.complex?.id
    }));
  };
  useEffect(() => {
    setIsPlayed({
      [index]: true
    });
  }, [reel, index]);
  const brandUrl = `${process.env.NEXT_PUBLIC_FRONT_URL}${rootPaths.BUILDERS}/${reel?.brand?.slug}`;
  const complexUrl = `${process.env.NEXT_PUBLIC_FRONT_URL}/${reel?.complex?.city_url_name}${rootPaths.RESIDENTIAL_COMPLEXES}/${reel?.complex?.slug}-${reel?.complex?.id}`;
  return <div className="flex items-center justify-center h-full rounded-xl overflow-hidden group cursor-pointer" data-sentry-component="ReelsVideoContent" data-sentry-source-file="reels-video-content.tsx">
      <div className='absolute w-full h-full top-0 left-0 rounded-xl overflow-hidden' style={{
      backgroundImage: `url(${reel?.image})`,
      filter: "blur(10px)",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }} />
      <div className="absolute flex items-center justify-between z-10 top-[68px] md:top-0 w-full p-5 group-hover:opacity-100 md:opacity-0 opacity-100 transition-all duration-150">
        <span className="text-base font-bold text-white">UYSOT REELS</span>
        <div className='flex items-center gap-3'>
          {isMuted ? <MuteIcon className='md:w-12 md:h-12 w-[38px] h-[38px] cursor-pointer' onClick={e => toggleMute(e)} /> : <SoundIcon className='md:w-12 md:h-12 w-[38px] h-[38px] cursor-pointer' onClick={e => toggleMute(e)} />}
          <div className="md:w-12 md:h-12 w-[38px] h-[38px] flex items-center justify-center cursor-pointer rounded-full bg-[#00000030]" onClick={handleClose}>
            <CloseIcon fill="#fff" data-sentry-element="CloseIcon" data-sentry-source-file="reels-video-content.tsx" />
          </div>
        </div>
      </div>
      <video ref={el => {
      if (el) videoRefs.current[index] = el;
    }} loop src={reel.file} data-id={reel.id} className="relative z-[9]" onClick={e => handlePlay(e)} />
      {!isPlayed[index] && <div className='absolute z-10 bottom-0 left-0 w-full h-[80%] md:h-full flex items-center justify-center' style={{
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 55%, rgba(0, 0, 0, 0.90) 96.09%)'
    }} onClick={e => handlePlay(e)}>
          <PauseIcon className='md:w-12 md:h-12 w-[38px] h-[38px] cursor-pointer' />
        </div>}
      <div className='absolute w-full bottom-0 left-0 h-max rounded-none md:rounded-xl py-3 px-5 z-10' style={{
      background: "linear-gradient(180deg, rgba(118, 118, 118, 0.00) 0%, #000 135.44%)"
    }}>
        <div className='flex flex-col gap-5'>
          <CustomLink href={brandUrl} className='flex items-center gap-2 w-full' data-sentry-element="CustomLink" data-sentry-source-file="reels-video-content.tsx">
            <div className='w-[38px] h-[38px] min-w-[38px] min-h-[38px] rounded-full overflow-hidden border border-solid border-white'>
              <CustomImage alt={reel?.brand?.name} src={reel?.brand?.logo} data-sentry-element="CustomImage" data-sentry-source-file="reels-video-content.tsx" />
            </div>
            <span className='text-white font-semibold md:text-sm text-xs'>{reel?.brand?.name}</span>
          </CustomLink>
          <div className='flex items-end gap-4' onClick={e => handleMore(e)}>
            <div className={`text-white font-medium scrollable-div ${more ? 'w-full line-clamp-none mainFilter max-h-[160px] overflow-y-auto' : reel?.caption?.length > 100 ? `w-[75%] line-clamp-2` : ''}`} onTouchEndCapture={e => {
            if (!contentRef.current) {
              e.stopPropagation();
            }
          }} onScroll={e => {
            if (e.currentTarget.scrollTop === 0) {
              contentRef.current = true;
            }
            if (e.currentTarget.scrollTop + e.currentTarget.clientHeight === e.currentTarget.scrollHeight) {
              contentRef.current = true;
            } else {
              contentRef.current = false;
            }
          }}>
              {reel?.caption}
            </div>
            {reel?.caption?.length > 100 ? <div className={`text-white underline cursor-pointer ${more ? 'hidden' : 'block'}`} onClick={e => handleMore(e)}>
                {layoutLang[locale || 'ru']['yana']}
              </div> : ''}
          </div>
        </div>
        <div className='pb-2 pt-4 flex items-center gap-2.5 cursor-pointer'>
          <div className='flex-1 h-[50px] flex items-center justify-center rounded-xl bg-[#00A389] text-white text-[15px] font-semibold' onClick={handleCallBack}>
            {layoutLang[locale || 'ru']['Konsultatsiya']}
          </div>
          <CustomLink href={reel?.complex ? complexUrl : brandUrl} className='flex-1 h-[50px] flex items-center justify-center rounded-xl bg-[#F2F2F0] text-black text-[15px] font-semibold' data-sentry-element="CustomLink" data-sentry-source-file="reels-video-content.tsx">
            {layoutLang[locale || 'ru']['Batafsil']}
          </CustomLink>
        </div>
      </div>
    </div>;
};
export default ReelsVideoContent;